<script lang="ts" setup>

const config = useRuntimeConfig()
const router = useRouter()
const dev_password = useCookie('dev_password')
definePageMeta({
    layout: false
})

useHead(
    {
        title: 'Enter to Dev Mode'
    }
)

const password = ref('')
const showError = ref(false)

function onSubmit() {
    if(config.public.devPassword !== password.value){
        showError.value = true
        return
    }
    dev_password.value = password.value
    router.replace('/')
};
</script>

<template>
    <div>
        <div class="min-h-screen bg-[#0C3B2E] flex items-center justify-center px-4 md:px-8">
            <div class="content w-full max-w-[650px] flex flex-col items-center">
                <img src="/img/coming_soon_logo.svg" alt="Yourservice">
                <form @submit.prevent="onSubmit" class="grid md:grid-flow-col gap-5 mt-[70px] w-full">
                    <UiInput v-model="password" required type="password" placeholder="Enter password" class="rounded-full px-6 font-normal h-12 w-full md:min-w-[450px]" />

                    <UiButton :disabled="!password" type="submit" variant="primary" class="rounded-full px-12 h-12">
                        Submit
                    </UiButton>
                </form>
            </div>
        </div>
        <UiDialog v-model:open="showError">
            <UiDialogContent class="p-0 gap-0">
                <UiDialogHeader class="p-5">
                    <div class="flex items-center justify-center h-16 w-16 rounded-[10px] bg-gray-100 mx-auto">
                        <Icon name="ri:error-warning-line" size="48px" class="text-red-700" />
                    </div>
                    <p class="font-medium mt-4 text-center text-2xl">Wrong password!</p>
                    <p class="text-sm text-gray-500 mt-1 text-center">Please contact the site administrator to grant access</p>
                </UiDialogHeader>
                <UiDialogFooter class="border-t border-gray-200 px-5 py-4">
                    <UiButton @click.prevent="showError = false" type="primary"
                              class="w-full bg-primary-500 hover:bg-primary-700 text-white text-sm w-fit px-10">Ок</UiButton>
                </UiDialogFooter>
            </UiDialogContent>
        </UiDialog>
    </div>
</template>